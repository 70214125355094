import './css/global.css';
import App from './js/app';

function Init() {
  App()
    .then(() => console.log('App loaded'))
    .catch((error) => console.log(error));
}

if (document.readyState === 'complete') {
  Init();
} else {
  document.addEventListener('DOMContentLoaded', () => Init());
}

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
